import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as playsHyGcVXcy8Meta } from "/opt/build/repo/pages/play.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "play",
    path: "/play",
    component: () => import("/opt/build/repo/pages/play.vue").then(m => m.default || m)
  }
]