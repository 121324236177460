import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoCross, LazySvgoDice, LazySvgoLogo, LazySvgoTailSpin } from '#components'
const lazyGlobalComponents = [
  ["SvgoCross", LazySvgoCross],
["SvgoDice", LazySvgoDice],
["SvgoLogo", LazySvgoLogo],
["SvgoTailSpin", LazySvgoTailSpin],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
