import { BrowserTracing, init, replayIntegration, vueRouterInstrumentation } from '@sentry/vue';

function getSentryIntegrations() {
    // don't load on server
    if (!import.meta.client) return [];

    const router = useRouter();
    const browserTracing = new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(router),
    });

    const replay = replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
    });

    return [browserTracing, replay];
}

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;

        const config = useRuntimeConfig();

        init({
            app: vueApp,
            dsn: config.public.SENTRY.DSN_PUBLIC ?? undefined,
            environment: config.public.SENTRY.ENVIRONMENT,
            integrations: getSentryIntegrations(),

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: config.public.SENTRY.TRACES_SAMPLE_RATE as number,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // This sets the sample rate. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: config.public.SENTRY.REPLAY_SAMPLE_RATE as number,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: config.public.SENTRY.ERROR_REPLAY_SAMPLE_RATE as number,
        });
    },
});
